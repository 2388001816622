<template>
  <div class="app-form">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">ID</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" :disabled="true" v-model="model.id">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Дата регистрации</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" :disabled="true" v-model="model.date">
          </div>
        </div>
      </div>
    </div>
<!--     <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Номер телефона</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.mobile_phone" v-mask="'8-999-999-99-99'">
          </div>
        </div>
      </div>
    </div> -->
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Почта</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="model.email">
          </div>
        </div>
      </div>
    </div>
<!--     <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Доступ до</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <b-datepicker
                v-model="model.date_expired"
                :show-week-number="true"
                placeholder="Выберите дату"
                trap-focus>
            </b-datepicker>
            </div>
          </div>
        </div>
      </div>
    </div> -->
<!--     <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Уведомлять</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="model.notify">
                <option
                  v-for="option in state_yesno"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Включен</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="model.is_active">
                <option
                  v-for="option in state_yesno"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--     <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Роль</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="model.role_id">
                <option
                  v-for="option in roles"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="field is-horizontal">
      <div class="field-label">
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <button class="button is-primary" @click="doUpdate()">
            Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getUser, updateUser } from '@/api/user'
// import { getRoles } from '@/api/role'
import { state_yesno, state_show, EventBus } from '@/utils'

export default {
  name: 'UserForm',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    const roles = []
    return {
      state_yesno: state_yesno,
      state_show: state_show,
      roles: roles,
      model: {
        id: null,
        date: null,
        email: null,
        username: null
      }
    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  mounted: function(){
    // let $this = this

    // getRoles(this.$store.state.jwt_user)
    //   .then(response => {
    //     let data = response.data;
    //     data.data.forEach((item) => {
    //       this.roles.push(item)
    //     })
    //   })
    //   .catch(
    //     this.roles = []
    //   )

    getUser(this.$route.params.id, this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        
        let result = data.data
        this.model = result

      })
      .catch(
        this.model = {}
      )
  },
  methods: {
    doUpdate () {
      let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          updateUser($this.model, this.$store.state.jwt_user).then(() =>
            {
              EventBus.$emit('successUpdateUser')
              this.$router.push({ name: 'a-users', params: {} })
            }
          )        

        }
      })
    },
  }
}
</script>

<style scoped>
  .app-form {
    text-align: left;
  }
</style>
<style>
</style>